<template>
  <aside class="site-sidebar" :class="'site-sidebar--' + sidebarLayoutSkin" >
    <div class="site-sidebar__inner" >
      <el-menu
        :default-active="menuActiveName || 'home'"
        :collapse="sidebarFold"
        :collapseTransition="false"
        class="site-sidebar__menu"
      >
       <component v-bind:is='currentSideBar'></component>
      </el-menu>
    </div>
  </aside>
</template>

<script>
import { isURL } from "@/utils/validate";
import exampleSideBar from './example_modules/sidebar.vue'
import example1SideBar from './example_module1/sidebar.vue'
import stationSideBar from './health_station/sidebar.vue'
import myh_SideBar from './personal_module/sidebar.vue'
import communitySideBar from './community_station/sidebar.vue'
import doctorSideBar from './doctor_station/sidebar.vue'
import WomenBabySideBar from './women_baby/sidebar.vue'
import OlderStationSideBar from './older_station/sidebar.vue'
import HomeOlderSideBar from './home_older/sidebar.vue'

// import personalSideBar from './personal_modules/sidebar'

export default {
  data() {
    return {
      dynamicMenuRoutes: [],
    };
  },
  components: {
    exampleSideBar,
    example1SideBar,
    stationSideBar,
    myh_SideBar,
    communitySideBar,
    doctorSideBar,
    WomenBabySideBar,
    OlderStationSideBar,
    HomeOlderSideBar
  },
  computed: {

    currentSideBar: function () {
      if (this.$store.state.module.moduleName === 'example_module') {
        return 'exampleSideBar'
      }
      else if (this.$store.state.module.moduleName === 'example_module1') {
        return 'example1SideBar'
      }
      else if (this.$store.state.module.moduleName === 'health_station') {
        return 'stationSideBar'
      }
      else if (this.$store.state.module.moduleName === 'personal_module') {
        return 'myh_SideBar'
      } 
      else if (this.$store.state.module.moduleName === 'community_station') {
        return 'communitySideBar'
      }
      else if (this.$store.state.module.moduleName === 'doctor_station') {
        return 'doctorSideBar'
      }
      else if (this.$store.state.module.moduleName === 'women_baby') {
        return 'WomenBabySideBar'
      }
      else if (this.$store.state.module.moduleName === 'older_station') {
        return 'OlderStationSideBar'
      }
      else if (this.$store.state.module.moduleName === 'home_older') {
        return 'HomeOlderSideBar'
      }
      else {
        return 'myh_SideBar'
      }
    },

    sidebarLayoutSkin: {
      get() { return this.$store.state.common.sidebarLayoutSkin; },
    },
    sidebarFold: {
      get() { return this.$store.state.common.sidebarFold; },
    },
    menuList: {
      get() { return this.$store.state.common.menuList; },
      set(val) { this.$store.commit("common/updateMenuList", val); },
    },
    menuActiveName: {
      get() { return this.$store.state.common.menuActiveName; },
      set(val) { this.$store.commit("common/updateMenuActiveName", val); },
    },
    mainTabs: {
      get() { return this.$store.state.common.mainTabs; },
      set(val) { this.$store.commit("common/updateMainTabs", val); },
    },
    mainTabsActiveName: {
      get() {return this.$store.state.common.mainTabsActiveName;},
      set(val) {this.$store.commit("common/updateMainTabsActiveName", val);},
    },
  },
  watch: {
    $route: "routeHandle",
  },
  created() {
    this.menuList = JSON.parse(sessionStorage.getItem("menuList") || "[]");
    this.dynamicMenuRoutes = JSON.parse(
      sessionStorage.getItem("dynamicMenuRoutes") || "[]"
    );
    this.routeHandle(this.$route);
  },
  methods: {
    // 路由操作
    routeHandle(route) {
      console.log(route)
      if (route.matched.length < 2) {
        return console.log("直接退出!");
      }
      if (route.meta.isTab || route.matched[1].meta.isTab) {
        // tab选中, 不存在先添加
        if (route.matched[1].meta.isTab) {
          var tab = this.mainTabs.filter(
            (item) => item.name === route.matched[1].name
          )[0];
        } else {
          tab = this.mainTabs.filter((item) => item.name === route.name)[0];
        }
        // console.log('看看route.name')
        // console.log(route.name)
        if (!tab) {
          if (route.meta.isDynamic) {
            route = this.dynamicMenuRoutes.filter(
              (item) => item.name === route.name
            )[0];
            if (!route) {
              return console.error("未能找到可用标签页!");
            }
          }
          tab = {
            menuId:
              route.meta.menuId || route.matched[1].meta.isTab
                ? route.matched[1].name
                : route.name,
            name: route.matched[1].meta.isTab
              ? route.matched[1].name
              : route.name,
            title: route.matched[1].meta.isTab
              ? route.matched[1].meta.title
              : route.meta.title,
            type: isURL(route.meta.iframeUrl) ? "iframe" : "module",
            iframeUrl: route.meta.iframeUrl || "",
            params: route.params,
            query: route.query,
          };
          this.mainTabs = this.mainTabs.concat(tab);
          // console.log('给爷看下面的东西')
          // console.log(tab.name)
        }
        this.menuActiveName = tab.menuId + "";
        this.mainTabsActiveName = tab.name;
      }
      // console.log('康康maintabs')
      // console.log(this.mainTabs)
    },
  },
};
</script>

<style>

  /*隐藏文字*/
  .el-menu--collapse  .el-submenu__title span{
    display: none;
  }
  /*隐藏 > */
  .el-menu--collapse  .el-submenu__title .el-submenu__icon-arrow{
    display: none;
  }

</style>
