<template>
  <div>
    <el-menu-item index="myhealthHome" @click="$router.push({ name: 'myhealthHome' })">
      <i name="shouye" class="el-icon-s-home"></i>
      <span slot="title" style="font-size: 18px">首页</span>
    </el-menu-item>

    <el-submenu index="information">
      <template slot="title">
        <i name="info" class="el-icon-s-custom"></i>
        <span style="font-size: 18px">个人数据</span>
      </template>
      <el-menu-item
        index="myh_history'"
        @click="$router.push({ name: 'myh_history' })"
      >
        <i name="tubiao" class="el-icon-document-copy"></i>
        <span slot="title" style="font-size: 18px">检测项目/记录</span>
      </el-menu-item>
      <el-menu-item
        index="myh_tend"
        @click="$router.push({ name: 'myh_tend' })"
      >
        <i name="tubiao" class="el-icon-data-analysis"></i>
        <span slot="title" style="font-size: 18px">总体趋势分析</span>
      </el-menu-item>
      <el-menu-item
        index="ExamReport"
        @click="$router.push({ name: 'ExamReport' })"
      >
        <i name="tubiao" class="el-icon-document"></i>
        <span slot="title" style="font-size: 18px">综合体检报告</span>
      </el-menu-item>
    </el-submenu>

    <!-- <el-submenu index="DoctorConsult">
      <template slot="title">
        <i name="info" class="el-icon-first-aid-kit"></i>
        <span style="font-size: 18px">医生咨询</span>
      </template>
          <el-menu-item
            index="ECG_consult"
            @click="$router.push({ name: 'ECG_consult' })"
          >
            <i name="tubiao" class="el-icon-s-claim"></i>
            <span slot="title" style="font-size: 18px">心电咨询</span>
          </el-menu-item>

          <el-menu-item
            index="RemoteDiagnosis"
            @click="$router.push({ name: 'RemoteDiagnosis' })"
          >
            <i name="editor" class="el-icon-phone-outline"></i>
            <span slot="title" style="font-size: 18px">远程诊疗</span>
          </el-menu-item>
    </el-submenu> -->
    <el-submenu index="remote">
      <template slot="title">
        <i name="info" class="el-icon-first-aid-kit"></i>
        <span style="font-size: 18px">远程诊疗</span>
      </template>

      <el-menu-item index="Appointment"
                    @click="$router.push({ name: 'Appointment' })">
        <i name="info" class="el-icon-s-cooperation"></i>
        <span slot="title" style="font-size: 18px">远程诊疗预约</span>
      </el-menu-item>

      <el-menu-item index="RemoteHistory"
                    @click="$router.push({ name: 'RemoteHistory' })">
        <i name="info" class="el-icon-s-cooperation"></i>
        <span slot="title" style="font-size: 18px">预约、问诊历史</span>
      </el-menu-item>

      <el-menu-item index="reportUpload"
                    @click="$router.push({ name: 'reportUpload' })">
        <i name="info" class="el-icon-s-cooperation"></i>
        <span slot="title" style="font-size: 18px">上传外部报告</span>
      </el-menu-item>

      <el-menu-item index="RemoteHistory"
                    @click="$router.push({ name: 'emergency3' })">
        <i name="info" class="el-icon-s-cooperation"></i>
        <span slot="title" style="font-size: 18px">急诊</span>
      </el-menu-item>
    </el-submenu>
    <el-menu-item
    index="PsInfo"
    @click="$router.push({ name: 'PsInfo' })">
        <i name="info" class="el-icon-notebook-2"></i>
        <span slot="title" style="font-size: 18px">个人信息表</span>
    </el-menu-item>

    <el-menu-item
    index="RelativeManage"
    @click="$router.push({ name: 'RelativeManage' })">
        <i name="info" class="el-icon-user-solid"></i>
        <span slot="title" style="font-size: 18px">亲情关怀</span>
    </el-menu-item>

    <el-menu-item
    index="AccountSetting"
    @click="$router.push({ name: 'AccountSetting' })">
        <i name="info" class="el-icon-user"></i>
        <span slot="title" style="font-size: 18px">账户设置</span>
     </el-menu-item>

    

    </div>

</template>

<script>
export default {
  data() {
    return {
      url: "47.106.217.34",
      dialogVisible: false
    };
  },
  components: {
    // SubMenu
  },
  computed: {
  },
  watch: {
  },
  created() {
  },
  methods: {

    handleClose(done) {
        this.$confirm('确认关闭？')
          .then(() => {
            done(
              this.dialogVisible = false
            )
            // window.location.href = "http://" + this.url;
          })
          .catch(() => {});
    },

    // 跳转到大屏
    toBigScreen() {
      this.dialogVisible = false
      window.location.href = "http://" + this.url;
    }
  },
};
</script>

<style scoped>

</style>
