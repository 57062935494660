<template>
  <div>
    <el-menu-item index="home" @click="$router.push({ name: 'home' })">
      <i name="shouye" class="el-icon-s-home"></i>
      <span slot="title" style="font-size: 16px">首页</span>
    </el-menu-item>

    <el-submenu index="user">
      <template slot="title">
        <i name="info" class="el-icon-star-on"></i>
        <span style="font-size: 16px">我的病人</span>
      </template>
      <el-menu-item
          index="memberData"
          @click="$router.push({ name: 'memberData_doctor' })"
      >
        <i name="tubiao" class="el-icon-user-solid"></i>
        <span slot="title" style="font-size: 16px">病人管理</span>
      </el-menu-item>

    </el-submenu>

    <el-submenu index="consultant">
      <template slot="title">
        <i name="info" class="el-icon-s-claim"></i>
        <span style="font-size: 16px">咨询服务</span>
      </template>
      <el-menu-item
          index="check"
          @click="$router.push({ name: 'checkList' })"
      >
        <i name="tubiao" class="el-icon-s-check"></i>
        <span slot="title" style="font-size: 16px">咨询列表</span>
      </el-menu-item>

      <!-- <el-menu-item
        index="checkHistory"
        @click="$router.push({ name: 'checkContent' })"
      >
        <i name="tubiao" class="el-icon-finished"></i>
        <span slot="title" style="font-size: 16px">心电复核</span>
      </el-menu-item> -->

    </el-submenu>

    <el-submenu index="remoteTreatment">
      <template slot="title">
        <i name="info" class="el-icon-video-camera"></i>
        <span style="font-size: 16px">远程诊疗</span>
      </template>
      <!-- <el-menu-item
          index="remoteDocPage3"
          @click="$router.push({ name: 'remoteDocPage3' })"
      >
        <i name="tubiao" class="el-icon-date"></i>
        <span slot="title" style="font-size: 16px">开放预约</span>
      </el-menu-item> -->
      <el-menu-item
        index="remotePage1"
        @click="$router.push({ name: 'remotePage1' })"
      >
        <i name="tubiao" class="el-icon-tickets"></i>
        <span slot="title" style="font-size: 16px">预约列表</span>
      </el-menu-item>
      <el-menu-item
        index="remotePage2"
        @click="$router.push({ name: 'remotePage2' })"
      >
        <i name="tubiao" class="el-icon-collection"></i>
        <span slot="title" style="font-size: 16px">出诊历史</span>
      </el-menu-item>

<!--      <el-menu-item-->
<!--          index="emergency2"-->
<!--          @click="$router.push({ name: 'emergency2' })"-->
<!--      >-->
<!--        <i name="tubiao" class="el-icon-search"></i>-->
<!--        <span slot="title" style="font-size: 16px">急诊</span>-->
<!--      </el-menu-item>-->

    </el-submenu>

    <el-submenu index="vip">
      <template slot="title">
        <i name="info" class="el-icon-s-custom"></i>
        <span style="font-size: 16px">VIP用户</span>
      </template>
      <el-menu-item
          index="vipMemberData"
          @click="$router.push({ name: 'vipMemberData' })"
      >
        <i name="tubiao" class="el-icon-date"></i>
        <span slot="title" style="font-size: 16px">VIP管理</span>
      </el-menu-item>
      <el-menu-item
        index="MonthlyReport"
        @click="$router.push({ name: 'MonthlyReport' })"
      >
        <i name="tubiao" class="el-icon-tickets"></i>
        <span slot="title" style="font-size: 16px">每月报告</span>
      </el-menu-item>

    </el-submenu>
    <!-- <el-submenu index="editPsInfo">
      <template slot="title">
        <i name="info" class="el-icon-user"></i>
        <span style="font-size: 16px">个人信息</span>
      </template>
      <el-menu-item
        index="editPsInfo"
        @click="$router.push({ name: 'editPsInfo' })"
      >
        <i name="tubiao" class="el-icon-edit"></i>
        <span slot="title" style="font-size: 16px">修改信息</span>
      </el-menu-item>
    </el-submenu> -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      url: "47.106.217.34",
      dialogVisible: false
    };
  },
  components: {
    // SubMenu
  },
  computed: {
  },
  watch: {
  },
  created() {
  },
  methods: {

    handleClose(done) {
        this.$confirm('确认关闭？')
          .then(() => {
            done(
              this.dialogVisible = false
            )
            // window.location.href = "http://" + this.url;
          })
          .catch(() => {});
    },

    // 跳转到大屏
    toBigScreen() {
      this.dialogVisible = false
      window.location.href = "http://" + this.url;
    }
  },
};
</script>

<style scoped>
/* .site-sidebar__menu template span {
  font-size: 16px;
} */
</style>
