<template>
  <div>
    <el-menu-item index="home" @click="$router.push({ name: 'home' })">
      <i name="shouye" class="el-icon-s-home"></i>
      <span slot="title" style="font-size: 16px">首页</span>
    </el-menu-item>

    <el-submenu index="information">
      <template slot="title">
        <i name="info" class="el-icon-s-custom"></i>
        <span style="font-size: 16px">页面</span>
      </template>
      <el-menu-item
        index="examplePage1"
        @click="$router.push({ name: 'example1Page1' })"
      >
        <i name="tubiao" class="el-icon-search"></i>
        <span slot="title" style="font-size: 16px">页面1</span>
      </el-menu-item>
      <el-menu-item
        index="examplePage2"
        @click="$router.push({ name: 'example1Page2' })"
      >
        <i name="tubiao" class="el-icon-search"></i>
        <span slot="title" style="font-size: 16px">页面2</span>
      </el-menu-item>
    </el-submenu>

  </div>
</template>

<script>
export default {
  data() {
    return {
      url: "47.106.217.34",
      dialogVisible: false
    };
  },
  components: {
    // SubMenu
  },
  computed: {
  },
  watch: {
  },
  created() {
  },
  methods: {

    handleClose(done) {
        this.$confirm('确认关闭？')
          .then(() => {
            done(
              this.dialogVisible = false
            )
            // window.location.href = "http://" + this.url;
          })
          .catch(() => {});
    },
    
    // 跳转到大屏
    toBigScreen() {
      this.dialogVisible = false
      window.location.href = "http://" + this.url;
    }
  },
};
</script>

<style scoped>
/* .site-sidebar__menu template span {
  font-size: 16px;
} */
</style>
